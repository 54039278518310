import axios from "@/libs/axios";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import store from "../store";
import router from "../router";

export const HYDROGRAPHIC_BASINS_LIST_FILE_LINK =
	"https://semente-media.espresso-cloud.com/_static/semente_lista-de-municipios.pdf";

const requestErrorHandler = async (error: any) => {
	console.error(error);
	if (!error.response) {
		throw new Error("Não foi possível conectar com o servidor. Verifique sua conexão com a Internet.");
	}

	switch (error.response.status) {
		case 401:
			await store.dispatch("auth/logout");
			router.replace({ name: "login" });
			return;
		default:
			throw { response: error.response.data, message: error.response.data?.message, statusCode: error.response.status };
	}
};

export function deflateEntity(entity: any, prefix = "") {
	if (typeof entity === "string") {
		return entity; // do not attempt to deflate strings
	}
	let flatEntity = Object.assign({}, entity);
	Object.keys(entity).forEach(key => {
		if (flatEntity[key] instanceof Array) {
			flatEntity[key] = flatEntity[key].map((subEntity: any) => deflateEntity(subEntity));
		}
		if (flatEntity[key] instanceof Object && !(flatEntity[key] instanceof Array)) {
			flatEntity = { ...flatEntity, ...deflateEntity(flatEntity[key], prefix ? `${prefix}.${key}` : key) };
			delete flatEntity[key];
		} else if (prefix) {
			flatEntity[`${prefix}.${key}`] = flatEntity[key];
			delete flatEntity[key];
		}
	});
	return flatEntity;
}

function inflateEntity(flatEntity: any) {
	if (typeof flatEntity === "string") {
		return flatEntity; // do not attempt to inflate strings
	}
	const entity = {};
	Object.keys(flatEntity).forEach(key => {
		let entityLevel: any = entity;
		const levelsKeys = key.split(".");
		levelsKeys.forEach((levelKey, level) => {
			if (level === levelsKeys.length - 1) {
				// if last level, set value
				entityLevel[levelKey] =
					flatEntity[key] instanceof Array
						? flatEntity[key].filter((subEntity: any) => subEntity).map((subEntity: any) => inflateEntity(subEntity))
						: flatEntity[key];
			} else if (!entityLevel[levelKey]) {
				// otherwise, initialize if necessary
				entityLevel[levelKey] = {};
			}
			entityLevel = entityLevel[levelKey]; // update current level
		});
	});
	return entity;
}

export async function post(controller: string, command: string, data: any) {
	try {
		const response = await axios.post(`${controller}/${command}`, data);
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudDescription(controller: string) {
	try {
		const response = await axios.get(`${controller}/entity_description`);
		// add necessary field joins for related properties
		const { data: entityDescription } = response;
		entityDescription.forEach((fieldDescription: any) => {
			const joinMatches = fieldDescription.key.match(/(.+)\..+/);
			if (joinMatches && joinMatches.length > 1) {
				fieldDescription.join = joinMatches[1];
			}
		});
		return entityDescription;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudCreate(controller: string, data: any) {
	try {
		const response = await axios.post(controller, inflateEntity(data));
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudQuery(controller: string, queryParameters: any) {
	const queryString = RequestQueryBuilder.create(queryParameters).query();
	try {
		const response = await axios.get(`${controller}?${queryString}`);
		if (response.data && Array.isArray(response.data)) {
			response.data = response.data.map((entity: any) => deflateEntity(entity));
		} else if (response.data && response.data.data && Array.isArray(response.data.data)) {
			response.data.data = response.data.data.map((entity: any) => deflateEntity(entity));
		}
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudRead(controller: string, id: string | number, queryParameters: any) {
	const queryString = RequestQueryBuilder.create(queryParameters).query();
	try {
		const response = await axios.get(`${controller}/${id}?${queryString}`);
		return response.data ? deflateEntity(response.data) : null;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudUpdate(controller: string, id: string | number, data: any) {
	try {
		const response = await axios.patch(`${controller}/${id}`, inflateEntity(data));
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudDelete(controller: string, id: string | number, auditNotes: string) {
	try {
		await axios.request({
			url: `${controller}/${id}`,
			method: "delete",
			data: { auditNotes },
		});
		return true;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudPost(controller: string, path: string, data?: any) {
	try {
		const response = await axios.post(`${controller}/${path}`, data);
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudPatch(controller: string, path: string, data?: any) {
	try {
		const response = await axios.patch(`${controller}/${path}`, data);
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudGet(controller: string, path: string, params?: any) {
	try {
		const response = await axios.get(`${controller}/${path}`, { params });
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function crudMetrics(controller: string, options: any, subEntity: string) {
	if (subEntity) {
		subEntity = `/${subEntity}`;
	}
	try {
		const response = await axios.get(`${controller}${subEntity}/metrics`, { params: options });
		return response.data;
	} catch (error) {
		return requestErrorHandler(error);
	}
}

export async function uploadFile(file: File, relatedUserId = "") {
	const formData = new FormData();
	formData.append("file", file);
	try {
		const response = await axios.post(`upload/admin/${relatedUserId}`, formData);
		if (response.data) {
			return response.data;
		}
		throw Error("API_DATA_NOT_FOUND");
	} catch (error) {
		return requestErrorHandler(error);
	}
}
