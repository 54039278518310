import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

Vue.use(VueRouter);

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: "/",
			name: "dashboard",
			component: () => import("@/views/dashboard/Dashboard.vue"),
			meta: {
				pageTitle: "Dashboard",
				breadcrumb: [
					{
						text: "Dashboard",
						active: true,
					},
				],
			},
		},
		{
			path: "/administradores/:id?",
			name: "administradores",
			component: () => import("@/views/administrators/Administrators.vue"),
			meta: {
				pageTitle: "Administradores",
			},
		},
		{
			path: "/projetos/:id?",
			name: "projetos",
			component: () => import("@/views/project/Projects.vue"),
			meta: {
				pageTitle: "Projetos",
			},
		},
		{
			path: "/projetos/:id/etapa/:step",
			name: "projeto",
			component: () => import("@/views/project/ProjectForm.vue"),
			meta: {
				pageTitle: "Projeto",
				navActiveLink: "projetos",
			},
		},
		{
			path: "/proponentes/:id?",
			name: "proponentes",
			component: () => import("@/views/proponents/Proponents.vue"),
			meta: {
				pageTitle: "Proponentes",
			},
		},
		{
			path: "/promotores/:id?",
			name: "promotores",
			component: () => import("@/views/Prosecutors.vue"),
			meta: {
				pageTitle: "Promotores",
			},
		},
		{
			path: "/assessores/:id?",
			name: "assessores",
			component: () => import("@/views/Assessors.vue"),
			meta: {
				pageTitle: "Assessores",
			},
		},
		{
			path: "/avaliadores/:id?",
			name: "avaliadores",
			component: () => import("@/views/Evaluators.vue"),
			meta: {
				pageTitle: "Avaliadores",
			},
		},
		{
			path: "/login",
			name: "login",
			component: () => import("@/views/Login.vue"),
			meta: {
				layout: "full",
				isPublic: true,
				redirectIfLoggedIn: true,
			},
		},
		{
			path: "/error-404",
			name: "error-404",
			component: () => import("@/views/error/Error404.vue"),
			meta: {
				isPublic: true,
				layout: "full",
			},
		},
		{
			path: "*",
			redirect: "error-404",
		},
	],
});

router.beforeEach((to, _, next) => {
	const isLoggedIn = store.getters["auth/isLoggedIn"];

	if (isLoggedIn && to.meta.redirectIfLoggedIn) {
		return next({ name: "dashboard" });
	}

	if (!isLoggedIn && !to.meta.isPublic) {
		return next({ name: "login" });
	}

	return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById("loading-bg");
	if (appLoading) {
		appLoading.style.display = "none";
	}
});

export default router;
